/* eslint camelcase: 0 */
import {
  AvailableDistributorsLsType,
  BillPresenceLsType,
  BlockRatesUsageLsType,
  ControlledLoadUsageLsType,
  PeakOffPeakUsageLsType,
  SolarDetailsLsType,
  TariffTypeLsType,
  UserTypeLsType,
  YesNoType,
} from './localStorageTypes';
import {
  LS_AVAILABLE_DISTRIBUTORS_COUNT,
  LS_AVAILABLE_DISTRIBUTORS,
  LS_SELECTED_DISTRIBUTOR,
  LS_BILL_PRESENCE_TYPE,
  LS_HOUSEHOLD_SIZE,
  LS_HAS_CONTROLLED_LOAD,
  LS_HAS_SOLAR,
  LS_SOLAR_DETAILS,
  LS_TARIFF_TYPE,
  LS_SINGLE_RATE_USAGE,
  LS_START_DATE,
  LS_END_DATE,
  LS_PEAK_OFF_PEAK_USAGE,
  LS_BLOCK_RATES_USAGE,
  LS_CONTROLLED_LOAD_USAGE,
  LS_USER_TYPE,
  LS_CURRENT_RETAILER,
  LS_CURRENT_PLAN,
  LS_CLIMATE_ZONE,
  LS_LOCALITY,
  LS_RESIDENCE_ID,
  LS_SELECTED_RECOMMENDED_PLAN,
  LS_ACTUAL_PLAN_DATA,
  LS_PARSED_BILL,
  LS_PARSED_BILL_ID,
  LS_DATA_RECOMMENDATION,
  LS_SELECTED_RECOMMENDED_PLAN_INDEX,
  LS_BUSINESS_ID,
  LS_PLANS_LIST_FINAL,
  LS_ACTIVE_STEP_URL,
  LS_TOTAL_RESULTS_MEETING_FILTERS,
  LS_SOLAR_CREDITS,
  LS_IS_ACTUAL_PLAN_USER_SELECTED,
  LS_HAS_LIFE_SUPPORT_EQUIPMENTS,
  LS_HAS_SAVE_LEAST,
  LS_FULL_NAME,
  LS_EMAIL,
  LS_MEMBER_ID,
  LS_MEDIAN_PLAN_FINAL_COST,
  LS_REGION_POSTAL_CODE,
  LS_RESULTS_TOKEN,
} from './localStorageKeys';
import { GetLocality_getLocality_distributors } from '../../apollo/__generated__/GetLocality';
import { GetRetailersBySupplyarea_getRetailersBySupplyarea } from '../../apollo/__generated__/GetRetailersBySupplyarea';
import { GetPlans_getPlansByRetailerSupplyarea } from '../../apollo/__generated__/GetPlans';
import { GetLocalities2_getLocalities } from '../../apollo/__generated__/GetLocalities2';
import {
  GetRecommendation,
  GetRecommendation_resultSack_actualPlan,
  GetRecommendation_resultSack_recommendationList,
} from '../../apollo/__generated__/GetRecommendation';
import { UploadBill_uploadBill_parsedBill } from '../../apollo/__generated__/UploadBill';
import { StepUrl } from '../enums';
import { GetRecommendationByToken } from '../../apollo/__generated__/GetRecommendationByToken';

const GATSBY_LOCAL_STORAGE_EXPIRE_TIME = parseInt(
  process.env.GATSBY_LOCAL_STORAGE_EXPIRE_TIME || '10080',
  10
);

/**
 * Set local storage item with expiration
 */
const setWithExpiry = (key: string, value: any) => {
  const now = new Date();
  const item = {
    value,
    expiry: now.getTime() + GATSBY_LOCAL_STORAGE_EXPIRE_TIME * 60 * 1000,
  };

  localStorage.setItem(key, JSON.stringify(item));
};

/**
 * Get local storage item with expiration
 */
const getWithExpiry = (key: string, defaultValue: any = null) => {
  try {
    if (typeof window === 'undefined') return null;

    const item = JSON.parse(localStorage.getItem(key) || 'null');
    if (!item) {
      return defaultValue;
    }
    const now = new Date();
    if (!item.expiry || now.getTime() > item.expiry) {
      return defaultValue;
    }
    if (typeof item.value === 'undefined') {
      return defaultValue;
    }
    return item.value;
  } catch (e) {
    return null;
  }
};

/**
 * User Type
 */
export const setUserType = (userType: UserTypeLsType) =>
  setWithExpiry(LS_USER_TYPE, userType);

export const getUserType = () =>
  getWithExpiry(LS_USER_TYPE) as UserTypeLsType | null;

/**
 * Locality
 */
export const setLocalityLS = (locality: GetLocalities2_getLocalities) =>
  setWithExpiry(LS_LOCALITY, locality);

export const getLocalityLS = () =>
  getWithExpiry(LS_LOCALITY) as GetLocalities2_getLocalities | null;

/**
 * Available distributors
 */
export const getAvailableDistributorsLS = () =>
  getWithExpiry(
    LS_AVAILABLE_DISTRIBUTORS
  ) as AvailableDistributorsLsType | null;

export const setAvailableDistributorsLS = (
  availableDistributors: AvailableDistributorsLsType
) => setWithExpiry(LS_AVAILABLE_DISTRIBUTORS, availableDistributors);

/**
 * Available distributors count
 */
export const setAvailableDistributorsCountLS = (
  availableDistributorsCount: number
) => setWithExpiry(LS_AVAILABLE_DISTRIBUTORS_COUNT, availableDistributorsCount);

export const getAvailableDistributorsCountLS = () =>
  getWithExpiry(LS_AVAILABLE_DISTRIBUTORS_COUNT) as number | null;

/**
 * Selected distributor
 */
export const setSelectedDistributorLS = (
  selectedDistributor: GetLocality_getLocality_distributors | null
) => setWithExpiry(LS_SELECTED_DISTRIBUTOR, selectedDistributor);

export const getSelectedDistributorLS = () =>
  getWithExpiry(
    LS_SELECTED_DISTRIBUTOR
  ) as GetLocality_getLocality_distributors | null;

/**
 * Climate zone
 */
export const setClimateZoneLS = (climateZone: number) =>
  setWithExpiry(LS_CLIMATE_ZONE, climateZone);
export const getClimateZoneLS = () =>
  getWithExpiry(LS_CLIMATE_ZONE) as number | null;

/**
 * Bill presence
 */
export const setBillPresenceType = (billPresence: BillPresenceLsType) =>
  setWithExpiry(LS_BILL_PRESENCE_TYPE, billPresence);

export const getBillPresenceType = () =>
  getWithExpiry(LS_BILL_PRESENCE_TYPE) as BillPresenceLsType | null;

/**
 * Household size
 */
export const setHouseholdSize = (householdSize: number) =>
  setWithExpiry(LS_HOUSEHOLD_SIZE, householdSize);

export const getHouseholdSize = () =>
  getWithExpiry(LS_HOUSEHOLD_SIZE) as number | null;

/**
 * Email
 */
export const setHasEmail = (hasEmail: string) =>
  setWithExpiry(LS_EMAIL, hasEmail);

export const getHasEmail = () => getWithExpiry(LS_EMAIL);

/**
 * Full Name
 */
export const setHasFullName = (hasFullName: string) =>
  setWithExpiry(LS_FULL_NAME, hasFullName);

export const getHasFullName = () => getWithExpiry(LS_FULL_NAME);

/**
 * Member ID
 */
export const setHasMemberId = (hasMemberId: string) =>
  setWithExpiry(LS_MEMBER_ID, hasMemberId);
export const getHasMemberId = () => getWithExpiry(LS_MEMBER_ID);

/**
 * Has save least
 */

export const setHasSaveLeast = (hasSaveLeast: number) =>
  setWithExpiry(LS_HAS_SAVE_LEAST, hasSaveLeast);

export const getHasSaveLeast = () =>
  getWithExpiry(LS_HAS_SAVE_LEAST, 100) as number | null;

/**
 * Has life support equipment
 */
export const setHasLifeSupportEquipmant = (
  hasLifeSupportEquipmant: YesNoType
) => setWithExpiry(LS_HAS_LIFE_SUPPORT_EQUIPMENTS, hasLifeSupportEquipmant);

export const getHasLifeSupportEquipmant = () =>
  getWithExpiry(LS_HAS_LIFE_SUPPORT_EQUIPMENTS) as YesNoType;

/**
 * Has controlled load
 */
export const setHasControlledLoad = (hasControlledLoad: YesNoType | null) =>
  setWithExpiry(LS_HAS_CONTROLLED_LOAD, hasControlledLoad);

export const getHasControlledLoad = () =>
  getWithExpiry(LS_HAS_CONTROLLED_LOAD) as YesNoType | null;

/**
 * Controlled Load Usage
 */
export const setControlledLoadUsage = (
  controlledLoadUsage: ControlledLoadUsageLsType
) => setWithExpiry(LS_CONTROLLED_LOAD_USAGE, controlledLoadUsage);

export const getControlledLoadUsage = () =>
  getWithExpiry(LS_CONTROLLED_LOAD_USAGE) as ControlledLoadUsageLsType | null;

/**
 * Has solar
 */
export const setHasSolarSystem = (hasSolar: YesNoType | null) =>
  setWithExpiry(LS_HAS_SOLAR, hasSolar);

export const getHasSolarSystem = () => getWithExpiry(LS_HAS_SOLAR) as YesNoType;

/**
 * Solar system details
 */
export const setSolarSystemDetails = (solarSystemDetails: SolarDetailsLsType) =>
  setWithExpiry(LS_SOLAR_DETAILS, solarSystemDetails);

export const getSolarSystemDetails = () =>
  getWithExpiry(LS_SOLAR_DETAILS, 0) as SolarDetailsLsType | null;

/**
 * Solar credits
 */
export const setSolarCredits = (solarSystemDetails: number) =>
  setWithExpiry(LS_SOLAR_CREDITS, solarSystemDetails);

export const getSolarCredits = () =>
  getWithExpiry(LS_SOLAR_CREDITS) as number | null;

/**
 * Tariff type
 */
export const setTariffType = (solarSystemDetails: TariffTypeLsType) =>
  setWithExpiry(LS_TARIFF_TYPE, solarSystemDetails);

export const getTariffType = () =>
  getWithExpiry(LS_TARIFF_TYPE) as TariffTypeLsType | null;

/**
 * Single rate usage
 */
export const setSingleRateUsage = (singleRateUsage: number | null) =>
  setWithExpiry(LS_SINGLE_RATE_USAGE, singleRateUsage);

export const getSingleRateUsage = () =>
  getWithExpiry(LS_SINGLE_RATE_USAGE) as number | null;

/**
 * Start date 'yyyy-MM-dd' format
 */
export const setStartDate = (startDate: string | null) =>
  setWithExpiry(LS_START_DATE, startDate);

export const getStartDate = () => getWithExpiry(LS_START_DATE) as string | null;

/**
 * End date 'yyyy-MM-dd' format
 */
export const setEndDate = (endDate: string | null) =>
  setWithExpiry(LS_END_DATE, endDate);

export const getEndDate = () => getWithExpiry(LS_END_DATE) as string | null;

/**
 * Peak / Off Peak Usage
 */
export const setPeakOffPeakUsage = (
  peakOffPeakUsage: PeakOffPeakUsageLsType | null
) => setWithExpiry(LS_PEAK_OFF_PEAK_USAGE, peakOffPeakUsage);

export const getPeakOffPeakUsage = () =>
  getWithExpiry(LS_PEAK_OFF_PEAK_USAGE) as PeakOffPeakUsageLsType | null;

/**
 * Block rates
 */
export const setBlockRatesUsage = (
  blockRatesUsage: BlockRatesUsageLsType | null
) => setWithExpiry(LS_BLOCK_RATES_USAGE, blockRatesUsage);

export const getBlockRatesUsage = () =>
  getWithExpiry(LS_BLOCK_RATES_USAGE) as BlockRatesUsageLsType | null;

/**
 * Current retailer
 * Current plan
 */
export const setCurrentRetailer = (
  currentRetailer: GetRetailersBySupplyarea_getRetailersBySupplyarea | null
) => setWithExpiry(LS_CURRENT_RETAILER, currentRetailer);

export const getCurrentRetailer = () =>
  getWithExpiry(
    LS_CURRENT_RETAILER
  ) as GetRetailersBySupplyarea_getRetailersBySupplyarea | null;

export const setCurrentPlan = (
  currentPlan: GetPlans_getPlansByRetailerSupplyarea | null
) => setWithExpiry(LS_CURRENT_PLAN, currentPlan);

export const getCurrentPlanLS = () =>
  getWithExpiry(
    LS_CURRENT_PLAN
  ) as GetPlans_getPlansByRetailerSupplyarea | null;

/**
 * Residence Id
 */
export const setResidenceId = (residenceId: string) =>
  setWithExpiry(LS_RESIDENCE_ID, residenceId);

export const getResidenceId = () =>
  getWithExpiry(LS_RESIDENCE_ID) as string | null;

/**
 * Business Id
 */
export const setBusinessId = (residenceId: string) =>
  setWithExpiry(LS_BUSINESS_ID, residenceId);

export const getBusinessId = () =>
  getWithExpiry(LS_BUSINESS_ID) as string | null;

/**
 * Selected plan
 */
export const setSelectedRecommendedPlan = (
  selectedRecommendedPlan: GetRecommendation_resultSack_recommendationList
) => setWithExpiry(LS_SELECTED_RECOMMENDED_PLAN, selectedRecommendedPlan);

export const getSelectedRecommendedPlan = () =>
  getWithExpiry(
    LS_SELECTED_RECOMMENDED_PLAN
  ) as GetRecommendation_resultSack_recommendationList | null;

export const setSelectedRecommendedPlanIndex = (
  selectedRecommendedPlanIndex: number
) =>
  setWithExpiry(
    LS_SELECTED_RECOMMENDED_PLAN_INDEX,
    selectedRecommendedPlanIndex
  );

export const getSelectedRecommendedPlanIndex = () =>
  getWithExpiry(LS_SELECTED_RECOMMENDED_PLAN_INDEX, 1);

/**
 * Current (actual) plan data
 */
export const setIsActualPlanUserSelectedLS = (isUserSelected: boolean) =>
  setWithExpiry(LS_IS_ACTUAL_PLAN_USER_SELECTED, isUserSelected);

export const getIsActualPlanUserSelectedLS = () =>
  getWithExpiry(LS_IS_ACTUAL_PLAN_USER_SELECTED) as boolean | null;

export const setActualPlanFinalLS = (
  actualPlanData: GetRecommendation_resultSack_actualPlan
) => setWithExpiry(LS_ACTUAL_PLAN_DATA, actualPlanData);

export const getActualPlanFinalLS = () =>
  getWithExpiry(
    LS_ACTUAL_PLAN_DATA
  ) as GetRecommendation_resultSack_actualPlan | null;

export const setMedianPlanFinalCostLS = (savings?: number) =>
  setWithExpiry(LS_MEDIAN_PLAN_FINAL_COST, savings);

export const getMedianPlanFinalCostLS = () =>
  getWithExpiry(LS_MEDIAN_PLAN_FINAL_COST, 0);
export const setRecommendationDataLS = (
  recommendationData: GetRecommendation | GetRecommendationByToken
) => setWithExpiry(LS_DATA_RECOMMENDATION, recommendationData);

export const getRecommendationDataLS = () =>
  getWithExpiry(LS_DATA_RECOMMENDATION) as
    | GetRecommendation
    | GetRecommendationByToken
    | null;

export const setPlansListFinalLS = (
  recommendationData: (GetRecommendation_resultSack_recommendationList | null)[]
) => setWithExpiry(LS_PLANS_LIST_FINAL, recommendationData);

export const getPlansListFinalLS = () =>
  getWithExpiry(
    LS_PLANS_LIST_FINAL
  ) as (GetRecommendation_resultSack_recommendationList | null)[];

/**
 * Parsed bill data and id
 */
export const setParsedBillLS = (parsedBill: UploadBill_uploadBill_parsedBill) =>
  setWithExpiry(LS_PARSED_BILL, parsedBill);

export const getParsedBillLS = () =>
  getWithExpiry(LS_PARSED_BILL) as UploadBill_uploadBill_parsedBill | null;

export const setParsedBillIdLS = (parsedBillId: string) =>
  setWithExpiry(LS_PARSED_BILL_ID, parsedBillId);

export const getParsedBillIdLS = () =>
  getWithExpiry(LS_PARSED_BILL_ID) as string | null;

/**
 * Result page filters
 */
export const setActiveStepUrlLS = (activeStep: StepUrl) =>
  setWithExpiry(LS_ACTIVE_STEP_URL, activeStep);

export const getActiveStepUrlLS = () =>
  getWithExpiry(LS_ACTIVE_STEP_URL) as StepUrl | null;

/**
 * Total results meeting filters
 */
export const setTotalResultsMeetingFilters = (totalResults: number) =>
  setWithExpiry(LS_TOTAL_RESULTS_MEETING_FILTERS, totalResults);

export const getTotalResultsMeetingFilters = () =>
  getWithExpiry(LS_TOTAL_RESULTS_MEETING_FILTERS) as number | null;

/**
 * Region PostalCode
 */
export const setRegionPostalCode = (code: string) =>
  setWithExpiry(LS_REGION_POSTAL_CODE, code);

export const getRegionPostalCode = () => getWithExpiry(LS_REGION_POSTAL_CODE);

export const removeRegionPostalCode = () => {
  localStorage.removeItem(LS_REGION_POSTAL_CODE);
};

/**
 * Convert types
 */
export const yesNoToBool = (yesNoValue: YesNoType | undefined | null) => {
  if (!yesNoValue) return yesNoValue;
  return yesNoValue === 'yes';
};

export const userTypeToLetter = (userType: UserTypeLsType) => {
  if (userType === 'business') return 'B';
  return 'R';
};

/**
 * Result Token
 */
 export const setResultsToken = (token: string) =>
 setWithExpiry(LS_RESULTS_TOKEN, token);
export const getResultsToken = () => getWithExpiry(LS_RESULTS_TOKEN);
