import React from 'react';
import { Link, navigate } from 'gatsby';
import classNames from 'classnames';
import { StepUrl } from '../utils/enums';
import { navigateAndSetActiveStep } from '../utils/utils';

const style = require('./Map.module.scss');

const links = [
  [StepUrl.URL_POSTCODE_HOUSEHOLD, 'Postcode Household', true],
  [StepUrl.URL_POSTCODE_BUSINESS, 'Postcode Business', true],
  [StepUrl.URL_DISTRIBUTOR_CHOICE, 'Distributor Choice', true],
  [StepUrl.URL_BILL_TYPE, 'Bill Type', true],
  [StepUrl.URL_YOUR_HOUSEHOLD, 'Your Household', true],
  [StepUrl.URL_ABOUT_YOUR_BILL, 'About Your Bill', true],
  [StepUrl.URL_BLOCK_RATES_TARIFF, 'Block Rates tariff', true],
  [StepUrl.URL_SINGLE_RATE_TARIFF, 'Single Rate Tariff', true],
  [StepUrl.URL_PEAK_OFF_PEAK_TARIFF, 'Peak / Off Peak Tariff', true],
  [StepUrl.URL_CONTROLLED_LOAD, 'Controlled Load', true],
  [StepUrl.URL_CONTROLLED_LOAD_USAGE, 'Controlled load usage', true],
  [StepUrl.URL_SOLAR_INSTALLED, 'Solar installed', true],
  [StepUrl.URL_SOLAR_INPUT, 'Solar generation exported', true],
  [StepUrl.URL_SOLAR_SYSTEM_DETAILS, 'About your solar system', true],
  [StepUrl.URL_RETAILER_CHOICE, 'Retailer Choice', true],
  [StepUrl.URL_RESULTS, 'Results', true],
  [StepUrl.URL_PLAN_INFO, 'Plan info', true],
  [StepUrl.URL_ALMOST_THERE, 'Almost there', true],
];

const Map = () => {
  return (
    <div className={style.mapWrapper}>
      <Link
        to="/"
        className={classNames(style.link, style.back)}
        onClick={(e) => {
          e.preventDefault();
          navigate(-1);
        }}
      >
        Back
      </Link>
      {links.map(([link, label, validated], index) => (
        <div className={style.link}>
          <Link
            to={link as string}
            key={index}
            style={{ color: validated ? '#323439' : '#888' }}
          >
            {label}
          </Link>
          &nbsp;&nbsp;
          {'[ '}
          <Link
            to={link as string}
            key={index}
            style={{ color: validated ? '#323439' : '#888' }}
            onClick={(e) => {
              e.preventDefault();
              navigateAndSetActiveStep(link as StepUrl);
            }}
          >
            F
          </Link>
          {' ]'}
        </div>
      ))}
    </div>
  );
};

export default Map;
